import { clsx } from 'clsx';
import { capitalize } from 'lodash';
import React, { type ReactElement, useCallback } from 'react';
import type { FieldValues, Path, PathValue } from 'react-hook-form';

import { BaseInput, type InputProps } from '@/client/design-system/components/base/fields';
import { Icon } from '@/client/design-system/components/icon';

const listFormat = new Intl.ListFormat('en');

interface OwnProps<TFormValues extends FieldValues> extends InputProps<TFormValues> {
  showClear?: boolean;
  success?: boolean;
  disableInputClassName?: boolean;
}

interface InputGroupProps {
  children: ReactElement<typeof Input> | Array<ReactElement<typeof Input>>;
  errors?: Record<string, string | undefined> | undefined;
}

export const InputGroup = ({ children, errors: _errors }: InputGroupProps) => {
  const childrenArray = React.Children.toArray(children) as Array<ReactElement<OwnProps<any>>>;
  const errors = Object.values(_errors ?? {});
  const validErrors = errors
    .filter((error) => !!error)
    .map((error) => (error?.endsWith('.') ? error.substring(0, error.length - 1) : error)) as string[] | undefined;
  const errorMessage =
    validErrors && validErrors.length > 0 ? capitalize(listFormat.format(validErrors).toLowerCase()) + '.' : undefined;

  console.log(validErrors);
  return (
    <div className='flex flex-col'>
      {childrenArray.map((child, index) => {
        const isFirst = index === 0;
        const isLast = index === childrenArray.length - 1;

        return React.cloneElement(child, {
          ...child.props,
          inputClassName: clsx(
            child.props.inputClassName,
            'focus:z-10',
            !isFirst && 'rounded-t-none',
            !isLast && 'rounded-b-none border-b-0',
            child.props.id && !!_errors?.[child.props.id] && '!border-b-1'
          ),
        });
      })}
      {errorMessage && <p className='mt-1 text-left text-small-plus text-destructive'>{errorMessage}</p>}
    </div>
  );
};

export const Input = <TFormValues extends FieldValues>({
  register,
  control,
  id,
  inputClassName,
  label,
  showClear,
  success,
  error,
  hideError = false,
  disabled = false,
  disableInputClassName = false,
  ...props
}: OwnProps<TFormValues>) => {
  const onClear = useCallback(() => {
    props.setValue(register.name, '' as PathValue<TFormValues, Path<TFormValues>>);
  }, [props, register.name]);

  return (
    <BaseInput
      disabled={disabled}
      hideError={hideError}
      id={id}
      label={label}
      register={register}
      control={control}
      containerClassName='group flex flex-col gap-1.5'
      labelClassName='text-left text-small-plus text-secondary'
      inputClassName={clsx(
        inputClassName,
        !disableInputClassName &&
          'h-10 rounded-[10px] border-1 bg-page-item-surface text-extra-large-plus text-primary !outline-none transition-all placeholder:text-tertiary md:text-regular-plus',
        error
          ? 'border-red caret-red focus:ring-[1.75px] focus:ring-red/40 focus:ring-offset-[1.5px] focus:ring-offset-page-item-surface'
          : `${
            success ? 'border-green/40' : 'border-alpha-10 caret-control-background hover:border-alpha-10/15'
          } focus:border-blue focus:ring-[1.75px] focus:ring-blue/40 focus:ring-offset-[1.5px] focus:ring-offset-page-item-surface`,
        success || showClear ? 'pl-3 pr-8' : 'px-3'
      )}
      errorClassName='text-left text-small-plus text-destructive'
      childrenClassName='flex px-3 justify-center'
      error={error}
      {...props}
    >
      {success ? (
        <div className='text-positive'>
          <Icon icon='circle-check' size={16} />
        </div>
      ) : (
        showClear && (
          <button
            type='button'
            className='text-secondary hover:text-primary active:text-primary/60 group-focus-within:text-primary'
            onClick={onClear}
          >
            <Icon icon='close' size={16} />
          </button>
        )
      )}
    </BaseInput>
  );
};
