import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { USERNAME_MAX_LENGTH } from './constants';

export const validateEmail = (email: string) => {
  if (!email) {
    return true;
  }
  if (email.length > 254) {
    return false;
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const cleanUsername = (username: string): string => {
  // remove all leading and trailing underscores
  username = username.replace(/^_+|_+$/g, '');

  // remove all non-alphanumeric or underscore characters
  username = username.replace(/[^a-zA-Z0-9_]/g, '');

  // contains maximum of 1 underscore, remove all subsequent underscores
  username = username.replace(/\_/g, (c, i, t) => (t.indexOf(c) === i ? c : ''));

  // length must be >= 2 characters
  // override with "player_xxxx" if not the case, collision will still be handled by oauth
  if (username.length < 2) {
    username = 'player_' + (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);
  }

  // trim the username to 20 characters
  // length operation: if the trimmed username is not unique, 4 random digits will REPLACE the last 4 chars
  // this is performed at on oauth
  username = username.substring(0, USERNAME_MAX_LENGTH);

  return username;
};

export const validateUsername = (username: any) => {
  if (!username) {
    return false;
  }
  if (username.length < 2 || username.length > 20) {
    return false;
  }
  const re = /^[a-zA-Z0-9]+(_[a-zA-Z0-9]+)*$/; // letters, numbers, and underscore only; underscore not at the end

  return re.test(String(username).toLowerCase());
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const validatePhone = (phone: string, region?: string): boolean => {
  try {
    const number = phoneUtil.parse(phone, region);
    return phoneUtil.isValidNumber(number);
  } catch {
    return false;
  }
};

export const parsePhoneWithFallbackToUS = (phone: string) => parsePhone(phone) || parsePhone(phone, 'US') || phone;

const parsePhone = (phone: string, region?: string) => {
  try {
    const parsedNumber = phoneUtil.parse(phone, region);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
  } catch {
    return undefined;
  }
};

export const getValidPhone = (phone?: null | string) => {
  if (!phone) {
    return null;
  }
  try {
    const number = phoneUtil.parse(phone);
    const valid = phoneUtil.isValidNumber(number);

    if (!valid) {
      return null;
    }
    return phoneUtil.format(number, PhoneNumberFormat.E164);
  } catch (_error: unknown) {
    return null;
  }
};

export const getAge = (timestamp: string): number => {
  const ageDifMs = Date.now() - Date.parse(timestamp);
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
