import { clsx } from 'clsx';
import type { JSX } from 'react';

interface SpinnerProps {
  size?: number;
  spinnerClassName?: string;
}

export const Spinner = ({ size = 32, spinnerClassName }: SpinnerProps): JSX.Element => (
  <div
    role='status'
    style={{
      width: size,
      height: size,
    }}
  >
    <svg
      className='mr-2 animate-spin'
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <mask id='spinnerMask'>
          <path
            d='M14.037 5.88238C14.357 7.07639 14.3157 8.33847 13.9183 9.509C13.521 10.6795 12.7854 11.7059 11.8048 12.4585C10.8241 13.211 9.64226 13.6558 8.40877 13.7366C7.17529 13.8175 5.94553 13.5307 4.875 12.9127C3.80448 12.2946 2.94128 11.373 2.39455 10.2643C1.84782 9.15566 1.64212 7.90978 1.80347 6.68422C1.96481 5.45866 2.48596 4.30847 3.301 3.3791C4.11603 2.44972 5.18836 1.7829 6.38237 1.46297'
            stroke='white'
            strokeWidth='2.5'
            strokeLinecap='round'
          />
        </mask>
      </defs>

      <foreignObject x='0' y='0' width='16' height='16' mask='url(#spinnerMask)'>
        <div
          className={clsx(
            'size-4 rounded-[50%] bg-[conic-gradient(var(--tw-gradient-stops))] from-blue-primary/0 from-25% to-blue-primary to-100%',
            spinnerClassName
          )}
        />
      </foreignObject>
    </svg>
    <span className='sr-only'>Loading...</span>
  </div>
);
