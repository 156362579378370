import React from 'react';

import { Spinner } from '@/client/design-system/components/spinner';

export interface ButtonLargeProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  loading?: boolean | string;
  disabled?: boolean;
}

export const BaseButton = ({ loading = false, disabled = false, className, children, ...props }: ButtonLargeProps) => (
  <button {...props} disabled={!!loading || disabled} className={className}>
    <div className='flex items-center justify-center gap-2'>
      {loading ? (
        <>
          <Spinner size={16} />
          {typeof loading === 'string' && <span>{loading}</span>}
        </>
      ) : (
        children
      )}
    </div>
  </button>
);
