import React, { type ChangeEvent, type JSX, type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import type { FieldPath, FieldValues, PathValue, UseFormWatch } from 'react-hook-form';

import type { InputProps } from '@/client/design-system/components/base/fields/index';

export interface BasePinInputProps<
  TFormValues extends FieldValues,
  TFieldName extends FieldPath<TFormValues> = FieldPath<TFormValues>,
> extends InputProps<TFormValues, TFieldName> {
  numInputs: number;
  wrapperClassName?: string;
  watch: UseFormWatch<TFormValues>;
  actionButton?: ReactNode;
}

// This is a modified version of https://github.com/devfolioco/react-otp-input/blob/main/src/index.tsx
export const BasePinInput = <
  N extends string,
  TFormValues extends FieldValues & Record<N, string>,
  TFieldName extends FieldPath<TFormValues> & N,
>({
    id,
    label,
    placeholder,
    actionButton,
    autoFocus,
    numInputs,
    register,
    control,
    setValue,
    watch,
    error,
    hideError = false,
    disabled = false,
    containerClassName,
    wrapperClassName,
    labelClassName,
    inputClassName,
    errorClassName,
    style,
    ...props
  }: BasePinInputProps<TFormValues, TFieldName>): JSX.Element => {
  const [activeInput, setActiveInput] = useState(-1);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const value = watch(register.name);
  const values: string[] = value ? value.split('') : [];

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numInputs);
  }, [numInputs]);

  const isInputValueValid = (digit: string) => !isNaN(Number(digit)) && digit.trim().length === 1;

  const changeCodeAtFocus = (digit: string) => {
    values[activeInput] = digit.slice(0, 1);
    setValue(register.name, values.join('') as PathValue<TFormValues, TFieldName>);
  };

  const focusInput = useCallback(
    (index: number) => {
      const activeInput = Math.max(Math.min(numInputs - 1, index), 0);

      if (inputRefs.current[activeInput]) {
        inputRefs.current[activeInput]?.focus();
        inputRefs.current[activeInput]?.select();
        setActiveInput(activeInput);
      }
    },
    [numInputs]
  );

  const clear = useCallback(() => {
    setValue(register.name, '' as PathValue<TFormValues, TFieldName>);
    focusInput(0);
  }, [focusInput, register.name, setValue]);

  useEffect(() => {
    if (value?.length === 0) {
      clear();
    }
  }, [clear, value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: digit } = event.target;

    if (isInputValueValid(digit)) {
      changeCodeAtFocus(digit);
      focusInput(activeInput + 1);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { nativeEvent } = event;
    if (!isInputValueValid(event.target.value)) {
      // @ts-expect-error - This was added previosly to handle and edge case
      // for dealing with keyCode "229 Unidentified" on Android. Check if this is
      // still needed.
      if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
        event.preventDefault();
        changeCodeAtFocus('');
        focusInput(activeInput - 1);
      }
      event.target.value = '';
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => (index: number) => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ([event.code, event.key].includes('Backspace')) {
      event.preventDefault();
      changeCodeAtFocus('');
      focusInput(activeInput - 1);
    } else if (event.code === 'Delete') {
      event.preventDefault();
      changeCodeAtFocus('');
    } else if (event.code === 'ArrowLeft') {
      event.preventDefault();
      focusInput(activeInput - 1);
    } else if (event.code === 'ArrowRight') {
      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (event.key === values[activeInput]) {
      // React does not trigger onChange when the same value is entered
      // again. So we need to focus the next input manually in this case.

      event.preventDefault();
      focusInput(activeInput + 1);
    } else if (
      event.code === 'Spacebar' ||
      event.code === 'Space' ||
      event.code === 'ArrowUp' ||
      event.code === 'ArrowDown'
    ) {
      event.preventDefault();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    let nextActiveInput = activeInput;

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = event.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Prevent pasting if the clipboard data contains non-numeric values for number inputs
    if (pastedData.some((value) => isNaN(Number(value)))) {
      return;
    }

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        values[pos] = pastedData.shift() ?? '';
        nextActiveInput++;
      }
    }

    focusInput(nextActiveInput);
    setValue(register.name, values.join('') as PathValue<TFormValues, TFieldName>);
  };

  return (
    <div className={containerClassName} onPaste={handlePaste}>
      {(label || actionButton) && (
        <div className='flex justify-between'>
          {label && (
            <label htmlFor={id} className={labelClassName}>
              {label}
            </label>
          )}
          {actionButton}
        </div>
      )}
      <div className={wrapperClassName}>
        {Array.from({ length: numInputs }, (_, index) => index).map((index) => (
          <input
            id={id}
            key={index}
            ref={(element: HTMLInputElement | null) => {
              inputRefs.current[index] = element;
            }}
            className={inputClassName}
            style={style}
            placeholder={activeInput === index ? '' : placeholder}
            autoFocus={autoFocus && index === 0}
            disabled={disabled}
            value={values[index] ?? ''}
            onChange={handleChange}
            onInput={handleInputChange}
            onFocus={(event) => {
              handleFocus(event)(index);
            }}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            autoComplete='off'
            maxLength={1}
            inputMode='numeric'
            {...props}
          />
        ))}
      </div>
      {error && !hideError && <p className={errorClassName}>{(error as any) ?? ''}</p>}
    </div>
  );
};
